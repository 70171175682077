import router from '@/router'
import Vue from "vue";
import i18n from '@/i18n.js'
import AssignService from '../services/assign.service';
export const assign = {
    namespaced: true,
    state: () => ({
        platform_id: null
    }),
    mutations: {
        SET_PLATFORM(state, data) {
            state.platform_id = data
        }
    },
    actions: {
        // assign

        getCampByUser({ rootState }, value) {
            return AssignService.get_camps_by_user(value).then(
                (response) => {
                    // console.log(response)
                    rootState.form.style_form.map(function (v) {
                        if (v.value_text == "camp_id") {
                            v.items = response.data;
                            return v
                        }
                    });
                },
                (error) => {
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data.message,
                            type: "Danger",
                        };
                    }
                }
            );
        },
        delete_assign({ rootState }, item) {

            return AssignService.destroy_assign(item).then(
                (response) => {
                    // console.log(response)
                    rootState.table.items = rootState.table.items.filter((v) => v.id != item);
                    return Promise.resolve(response)
                },
                (error) => {
                    console.log(error);
                    if (error.response.status != 401) {
                        return Promise.reject(error)
                    }
                }
            );

        },
        add_assign({ rootState, state }, data) {
            // data.append('platform_id', state.platform_id)
            return AssignService.add_assign(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "AssignPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status == 500) {
                        rootState.form.notify = {
                            msg: error.response.data.data,
                            type: "Danger",
                        };
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            );

        },
        update_assign({ rootState, state }, data) {
            // data.append('platform_id', state.platform_id)
            return AssignService.edit_assign(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "AssignPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data,
                            type: "Danger",
                        };
                    }
                }
            );

        },

        update_re_assign({ rootState }, data) {
            return AssignService.edit_assign_by_company(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "AssignPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            );

        },

        update_country({ rootState, commit }, data) {

            return AssignService.update_country(data.data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    rootState.table.items.map(v => {
                        if (v.id == data.id) {
                            v.country_id = response.data.data.country_id
                            v.get_country = response.data.data.get_country
                            return v;
                        }
                    });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data,
                            type: "Danger",
                        };
                    }
                }
            );
        },
        action_upload_attachment({ rootState, commit }, data) {

            return AssignService.upload_attachment(data.id, data.data).then(
                (response) => {
                    console.log(response);
                    // rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    rootState.table.items.map(v => {
                        if (response.data.data.kroky_attachment && response.data.data.kroky_attachment != null)
                            v.get_kroky = [response.data.data.kroky_attachment]
                        if (response.data.data.assign_attachment && response.data.data.assign_attachment != null) {
                            // alert(3)
                            v.get_assign_file = [response.data.data.assign_attachment]
                        }
                    });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    // rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data,
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },

        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            return AssignService.get_assigns(rootState.table.paginate.page, formData).then(
                (response) => {
                    console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {

                        rootState.form.filters = response.data.filters;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

    },

};