import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";

// question
const QuestionPage = () => import("@/modules/form/views/Questions/Show.vue");
const NewQuestion = () => import("@/modules/form/views/Questions/New.vue");
const EditQuestion = () => import("@/modules/form/views/Questions/Edit.vue");

// form
const FormPage = () => import("@/modules/form/views/TamplateForm/Show.vue");
const NewForm = () => import("@/modules/form/views/TamplateForm/New.vue");
const EditForm = () => import("@/modules/form/views/TamplateForm/Edit.vue");
const AssignPriceToQuestion = () => import("@/modules/form/views/TamplateForm/AssignPriceToQuestion.vue");

// Question Category
const QuestionCategoryPage = () => import("@/modules/form/views/QuestionCategory/Show.vue");
const NewQuestionCategory = () => import("@/modules/form/views/QuestionCategory/New.vue");
const EditQuestionCategory = () => import("@/modules/form/views/QuestionCategory/Edit.vue");

// appointments
const AppointmentForm = () => import("@/modules/assign_camp/views/Appointments/Form.vue");
const AnswerForm = () => import("@/modules/assign_camp/views/Appointments/FormAnswer.vue");
const AnswerFormEdit = () => import("@/modules/assign_camp/views/Appointments/FormAnswerEdit.vue");
const SignatureForm = () => import("@/modules/assign_camp/views/Appointments/FormSignature.vue");
const SignatureAppointment = () => import("@/modules/assign_camp/views/Appointments/Signature.vue");


export const questionsPage = {
    path: "/",
    component: DashboardLayout,
    name: "Question",
    children: [{
        path: "/questions/create",
        name: "NewQuestion",
        component: NewQuestion,
        meta: {
            groupName: "Question",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "question-create",
        },
    },
    {
        path: "/questions/update/:id",
        name: "EditQuestion",
        component: EditQuestion,
        meta: {
            groupName: "Question",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "question-update",
        },
    },
    {
        path: "/questions",
        name: "QuestionPage",
        component: QuestionPage,
        meta: {
            groupName: "Question",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "tamplate-question-index",
        },
    },
    ],
};

export const questionCategoryPage = {
    path: "/",
    component: DashboardLayout,
    name: "QuestionCategory",
    children: [{
        path: "/question-category/create",
        name: "NewQuestionCategory",
        component: NewQuestionCategory,
        meta: {
            groupName: "QuestionCategory",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "question_category-create",
        },
    },
    {
        path: "/question-category/update/:id",
        name: "EditQuastionCategory",
        component: EditQuestionCategory,
        meta: {
            groupName: "QuestionCategory",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "question_category-update",
        },
    },
    {
        path: "/question-category",
        name: "QuestionCategoryPage",
        component: QuestionCategoryPage,
        meta: {
            groupName: "QuestionCategory",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "tamplate-question_category-index",
        },
    },
    ],
};

export const AnswerFromPage = {
    path: "/",
    component: DashboardLayout,
    name: "Form",
    children: [

        // {
        //     path: "appointments/:id/form",
        //     name: "AppointmentForm",
        //     component: AppointmentForm,
        //     meta: {
        //         groupName: "AnswerForm",
        //         middlewares: [
        //             Middleware.auth,
        //             Middleware.permission,
        //             Middleware.signeture,
        //         ],
        //         permissions: "appointment-form-view",
        //     },
        // },
        {
            path: "appointments/:id/form/:form",
            name: "AppointmentAnswerForm",
            component: AnswerForm,
            meta: {
                groupName: "AnswerForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "appointment-form-answer",
            },
        },

        {
            path: "appointments/:id/form/:form/edit",
            name: "AppointmentAnswerFormEdit",
            component: AnswerFormEdit,
            meta: {
                groupName: "AnswerForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "appointment-form-answer-edit",
            },
        },
        {
            path: "appointments/:id/form/:form/signature",
            name: "AppointmentFormSignature",
            component: SignatureForm,
            meta: {
                groupName: "AnswerForm",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "delivery-sign",
            },
        },
        {
            path: "appointments/:id/signature",
            name: "AppointmentSignature",
            component: SignatureAppointment,
            meta: {
                groupName: "SignatureAppointment",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "delivery-sign",
            },
        },
    ],
};

export const TamplateFormPage = {
    path: "/",
    component: DashboardLayout,
    name: "TamplateForm",
    children: [{
        path: "/forms/create",
        name: "NewForm",
        component: NewForm,
        meta: {
            groupName: "Form",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "tamplate-form-create",
        },
    },
    {
        path: "/forms/update/:id",
        name: "UpdateForm",
        component: EditForm,
        meta: {
            groupName: "Form",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "tamplate-form-update",
        },
    },
    {
        path: "/forms/questions-price/:id",
        name: "EditForm",
        component: AssignPriceToQuestion,
        meta: {
            groupName: "Form",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "tamplate-form-question",
        },
    },
    {
        path: "/forms",
        name: "FormPage",
        component: FormPage,
        meta: {
            groupName: "Form",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "tamplate-form-index",
        },
    },
    ],
};

