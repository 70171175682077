import { saveRedirectionIntoStorage } from "@/util/helpers/LoginRedirectionHelpers/LoginRedirectionHelpers";

export default function authReject({ nextMiddleware, contextNext, store, to }) {
  if (!store.state.auth.loggedIn) {
    saveRedirectionIntoStorage(to.fullPath);
    return contextNext({
      path: "/login",
    });
  }
  else {
    // alert(store.state.auth.user.is_acknowledged)
    // alert(store.state.auth.is_policy)
    if (store.state.auth.user && store.state.auth.user.is_acknowledged == 0 && store.state.auth.is_policy && store.state.auth.user.type_id != 1) {
      return contextNext({
        path: "/login",
      });
    } else {
      // alert(3)
      if (
        store.state.auth.user.gov_status != "rejected" &&
        store.state.auth.user.gov_status != "pending"
      ) {
        return contextNext({
          path: "/403",
        });
      }

    }
  }

  return nextMiddleware();
}
