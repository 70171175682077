import Vue from 'vue'
class CategoryService {
  // categories
  get_categories() {
    return axios.get('general/gov-category')
  }
  get_category(id) {
    return axios.get('general/gov-category/edit/' + id)
  }
  add_category(data) {
    return axios.post('general/gov-category/store', data)
  }
  edit_category(id, data) {
    return axios.post('general/gov-category/update/' + id, data)
  }
  destroy_category(id) {
    return axios.delete('general/gov-category/destroy/' + id)
  }
}
export default new CategoryService();